import { t } from "@lingui/macro";
import { Book, SignOut } from "@phosphor-icons/react";
import { Button } from "@rocket-resume/ui";
import { Link } from "react-router-dom";

import { useLogout } from "@/client/services/auth";
import { useAuthStore } from "@/client/stores/auth";

export const HeroCTA = () => {
  const { logout } = useLogout();

  const isLoggedIn = useAuthStore((state) => !!state.user);

  if (isLoggedIn) {
    return (
      <>
        <Button asChild size="lg" className="border border-black w-[10rem] h-[1.5rem] md:w-[12rem] md:h-[2rem] lg:w-[15rem] lg:h-[2.5rem] bg-[#FFEB00] text-[#000] hover:bg-[#e5e4e4]">
          <Link to="/dashboard">{t`Go to Dashboard`}</Link>
        </Button>

        <Button size="lg" variant="link" onClick={() => logout()} className="ml-2 text-[#fff]">
          <SignOut className="mr-2" />
          {t`Logout`}
        </Button>
      </>
    );
  }

  return (
    <>
      <Button asChild size="lg" variant='outline' className="border border-black w-[10rem] h-[1.5rem] md:w-[12rem] md:h-[2rem] lg:w-[15rem] lg:h-[2.5rem] bg-[#FFEB00] text-black text-[12px] xs:text-[13px] md:text-[17px]">
        <Link to="/auth/login"> {t`Get Started`} </Link>
      </Button>

      {/* <Button asChild size="lg" variant="link">
        <a href="https://docs.rxresu.me" target="_blank" rel="noopener noreferrer nofollow">
          <Book className="mr-3" />
          {t`Learn more`}
        </a>
      </Button> */}
    </>
  );
};
